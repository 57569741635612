import { getContext, setContext } from 'svelte';
import { writable, type Writable } from 'svelte/store';

export type Breadcrumb = {
    name: string;
    url: string;
};

export function setupBreadcrumbs(): void {
    const breadcrumbs: Writable<Breadcrumb[]> = writable([]);
    setContext('breadcrumbs', breadcrumbs);
}

export function getBreadcrumbs(): Writable<Breadcrumb[]> {
    return getContext('breadcrumbs');
}

export function setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    getBreadcrumbs().set(breadcrumbs);
}
